.logoPage {
	background: #cfa79d;
	height: 100vh;
	position: relative;
}
.logoPage > span {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
}
@media (max-width: 768px) {
	.logoPage {
		height: 30vh;
	}
}
