.calendarHeader {
	font-size: 15px;
	font-weight: 400;
}
.calendarHeader .calendarHeaderTitle {
	margin: 20px auto;
	text-align: center;
}
.calendarHeader .calendarHeaderContainer .CalendarControls {
	cursor: pointer;
	transform: translateY(10px);
}
.guruCard.calendar {
	text-align: center;
}
.guruCard.calendar .timeSlot {
	background-color: #f8f2df;
	border-radius: 44px;
	width: 90%;
	margin-bottom: 10px;
}
