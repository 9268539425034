.landing-page .content{
    padding: 20px 300px;
}
.landing-page .ant-upload-list-item{
    display: none;
}
.landing-page label{
    font-size: 23px ;
    font-weight: 600;
    margin-right: 20px;
}
.landing-page input{
    width: 400px;
    display: flex;
    flex:1;
    height: 42px;
    padding:2px 10px;
    box-shadow: 0px 4px 8px 0px rgba(44, 39, 56, 0.04);
    border-radius: 20px;
    border: 1px solid rgba(216, 216, 209, 1);
}
.landing-page .upload-area{
    width: 400px;
    display: flex;
    flex:1;
    height: max-content;
    padding:2px 10px;
    box-shadow: 0px 4px 8px 0px rgba(44, 39, 56, 0.04);
    font-size: 18px;
    font-weight: 600;
    border-radius: 20px;
    border: 1px solid rgba(216, 216, 209, 1);
}
.landing-page .ant-form-item{margin: 0;}
.landing-page .text-red{
    color:#F30E0E
}
.landing-page .ant-row {
    display: flex;
    align-items: center;
}
.landing-page .secondary_btn{
    background-color: rgba(251, 247, 236, 1);
    color: rgba(207, 167, 157, 1);
    font-weight: 700;
    font-size: 17px;
}