.tableHeader {
	background: #ededed;
	height: 70px;
	padding: 15px 20px;
}
.tableHeader span {
	font-size: 20px;
	color: #707070;
}
.tableHeader .searchBox {
	position: relative;
}
.tableHeader .searchBox input {
	border-radius: 18px;
	border: none;
	height: 40px;
	padding: 20px;
}
.notification-message .title {
	color: #fff;
}
.tableHeader .searchBox input:focus {
	outline: none;
}

.tableHeader .searchBox input::placeholder {
	font-size: 14px;
}
.tableHeader .searchBox img {
	position: absolute;
	top: 50%;
	right: 15px;
	transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	cursor: pointer;
}
.tableHeader button {
	float: right;
	margin-right: 20px;
}
.tableHeader > img {
	cursor: pointer;
}
.ant-drawer-body {
	padding: 0 !important;
}
