.modalBoxs {
	width: 90%;
	border: 1px solid #707070;
	position: relative;
	padding: 10px;
	margin: 0 auto 20px;
}
.modalBoxs label {
	background-color: #fff;
	position: absolute;
	top: -15px;
	left: 20px;
	padding: 0 10px;
	color: #cfa79d;
	font-size: 16px;
}

.modalBoxs .boxContent {
	padding: 10px;
}
.modalBoxs .boxContent.flex {
	display: flex;
}
.modalBoxs .boxContent > img {
	width: 60px;
	height: 60px;
	border-radius: 50%;
}

.modalBoxs .boxContent > span {
	padding: 5px 0 0 5px;
	color: #898989;
}

.modalBoxs .boxContent div {
	font-size: 18px;
	font-weight: 400;
	margin: 5px 0;
}
