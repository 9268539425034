.guruCard .profileBox > img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
  display: block;
}

.guruCard .watchVidBtn,
.guruCard .watchVidBtn:focus {
  margin: 10px 0;
  border: 1px solid #cfa79d;
  border-radius: 27px;
  font-size: 12px;
  font-weight: 500;
  height: 25px;
  line-height: 12.5px;
  color: #707070;
}
.guruCard .timeSlot {
  position: relative;
  cursor: pointer;
}
.guruCard .timeSlot.discountSlot,
.guruCard .timeSlot.discountSlot:hover {
  background-color: rgba(255, 0, 0, 0.527);
  color: #fff;
}
.guruCard .ck.ck-content {
  max-height: 200px;
  overflow: auto;
}
.guruCard .timeSlot.border-red {
  border: 1px solid red;
}
.ant-picker-time-panel-cell.ant-picker-time-panel-cell-disabled {
  display: none !important;
}
.guruCard .timeSlot:hover {
  color: #fff;
  background: #ae8f7b;
}

.guruCard .watchVidBtn:hover {
  color: #707070;
  border: 1px solid #cfa79d;
  box-shadow: 0px 7px 10px rgba(207, 167, 157, 0.6);
}
.guruCard .watchVidBtn > img {
  margin-left: 4px;
}
.guruCard .infoBox h2,
.guruCard .infoBox h4,
.guruCard .infoBox h5 {
  margin: 0;
  padding: 0;
}

.guruCard .infoBox h5 {
  color: #cfa79d;
  padding-left: 5px;
}
.guruCard p {
  color: #a3a3a3;
}
.guruCard .pricePerHour {
  color: #cfa79d;
  font-size: 18px;
  font-weight: 500;
}

.generalInfoBox {
  display: flex;
}
.generalInfoBox > div:first-of-type {
  color: #a3a3a3;
}
.generalInfoBox > div:nth-of-type(2) {
  margin-left: 30px;
  color: #cfa79d;
}

.css-b62m3t-container {
  width: 200px !important;
}
