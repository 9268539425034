.newAccountModal .ant-checkbox-checked .ant-checkbox-inner {
	background: red;
}
.newAccountModal label {
	font-size: 20px;
	font-weight: 500;
}
.newAccountModal .checkBoxBlock {
	margin-bottom: 20px;
	width: 100%;
}
.newAccountModal .bio-container{
	width: 90%;
}
.newAccountModal .bio-container>span:nth-of-type(2){
	width: 100%;
}
.newAccountModal .checkBoxBlock p {
	font-size: 14px;
	font-weight: lighter;
	color: #b2b2b2;
}

.newAccountModal textarea {
	width: 90%;
	resize: none;
	height: 100px;
	border: 1px solid #cfa79d;
	border-radius: 6px;
}
.newAccountModal textarea:focus {
	outline: none;
}
.newAccountModal .video-react-video,
.newAccountModal .video-react,
.newAccountModal .video-react-poster {
	border-radius: 50%;
}
.newAccountModal .video-react-big-play-button.video-react-big-play-button-left {
	margin-top: 27px;
	margin-left: 6px;
}
.newAccountModal .video-react-control-bar {
	display: none !important;
}
.newAccountModal .videoContainer {
	margin-left: 15px;
}

.documentContainer,
.imageContainer {
	text-align: center;
	margin: 0 auto;
}

.documentContainer img {
	margin: 0 15px;
	cursor: pointer;
}
.newAccountModal .docBox {
	padding-left: 20px;
}
.imageContainer > img {
	width: 400px;
}
@media (max-width: 769px) {
	.newAccountModal .checkBoxBlock {
		text-align: center;
	}
}
