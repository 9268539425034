.enterNewPassword {
	overflow-x: hidden;
}
.enterNewPassword a {
	color: #CFA79D;
}
.enterNewPassword .logo {
	display: inline-block;
	transform: scale(1.35);
	margin: 15px 40px;
}
.enterNewPassword .divider {
	height: 1px;
	background-color: #edeeef;
}
.enterNewPassword .label {
	font-size: 22px;
	font-weight: 700;
	position: absolute;
	top: 120px;
	left: 70px;
	width: 15%;
}

.enterNewPassword .label > div {
	font-size: 16px;
	color: #8d8d8d;
}
.enterNewPassword form {
	width: 32%;
	margin: 20px auto 10px;
	border-radius: 70px;
	box-shadow: 0 -5px 24px rgba(113, 113, 113, 0.13);
	position: relative;
	height: 550px;
}
.enterNewPassword form .inputBox {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
}
.enterNewPassword form .inputBox label {
	font-weight: 600;
	margin-top: 10px;
}
.enterNewPassword form .inputBox input {
	border: 1px solid #CFA79D;
}
.enterNewPassword .inputContainer {
	position: relative;
}
.enterNewPassword .inputContainer span {
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
}
.enterNewPassword form .btnContainer {
	position: absolute;
	bottom: 20px;
	left: 50%;
	transform: translate(-50%, -20px);
	width: 100%;
	margin: 0 auto;
	text-align: center;
}

.enterNewPassword form .btnContainer .btn {
	margin-right: 10px;
	border: 1px solid #CFA79D;
	border-radius: 26px;
	padding: 1px 35px !important;
	color: #CFA79D;
	text-align: center;
	font-weight: 600;
}
.enterNewPassword form .btnContainer .btn a:hover {
	color: #CFA79D;
	text-decoration: none;
}

.enterNewPassword form .btnContainer .btn a:nth-of-type() {
	color: #CFA79D;
}
.enterNewPassword form .btnContainer .btn:nth-of-type(2) {
	color: #fff;
	background-color: #CFA79D;
}
@media (min-width: 769px) and (max-width: 991px) {
	.enterNewPassword form {
		width: 50%;
	}
}
@media (max-width: 768px) {
	.enterNewPassword .logo {
		text-align: center;
		display: block;
	}
	.enterNewPassword .divider {
		display: none;
	}
	.enterNewPassword .label {
		top: 80px;
		left: 30px;
		width: 100%;
	}
	.enterNewPassword form {
		width: 100%;
		box-shadow: none;
		border-radius: 0px;
		height: calc(100vh - 80px);
	}
}
