.clientModal .personalInfoContainer {
	display: flex;
}
.clientModal .personalInfoContainer > div {
	margin: 20px;
}

.clientModal .generalInfoBox {
	margin-top: 20px;
}
